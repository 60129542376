import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAazVrRbBZBCvBUcwfpmDkQX_TwLgP-xnk",
  authDomain: "abracadabra-website.firebaseapp.com",
  projectId: "abracadabra-website",
  storageBucket: "abracadabra-website.appspot.com",
  messagingSenderId: "323937212187",
  appId: "1:323937212187:web:e8fd4a4dfc9b62074ce5be"
};

/* const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID
    
  }; */

  const firebaseApp = firebase.initializeApp(firebaseConfig);
  export const auth = firebase.auth();
  export const storage = firebase.storage();
  
/*   export { auth, storage }; */
  export default firebaseApp;