import React from 'react';

const ToggleSwitch = ({ handleToggle, item }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
      <div style={{ position: 'relative', display: 'inline-block', width: '8rem', height: '2rem' }}>
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: '#171717',
            borderRadius: '9999px',
            cursor: 'pointer',
            transition: 'background-color 0.3s'
          }}
          onClick={() => handleToggle(item)}
        >
          <span style={{ marginLeft: '0.75rem', fontSize: '0.85rem', color: 'white' }}>Visible</span>
          <span style={{ marginRight: '0.75rem', fontSize: '0.85rem', color: 'white' }}>Oculto</span>
          <div
            style={{
              position: 'absolute',
              width: '4rem',
              height: '2rem',
              backgroundColor: 'red',
              opacity: 0.5,
              borderRadius: '9999px',
              boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06)',
              transform: item.audioVisible ? 'translateX(0)' : 'translateX(4rem)',
              transition: 'transform 0.3s'
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ToggleSwitch;
