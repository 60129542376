import React, { useRef } from 'react';
import { useNavigate } from "react-router-dom";
import "./SignInScreen.css";
import { auth } from "../firebase.js";
import logo from "../assets/abracadabra-logo.svg";

function SignInScreen() {
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const navigate = useNavigate();

    /* const register = (e) => {
        e.preventDefault();

        auth
            .createUserWithEmailAndPassword(
                emailRef.current.value,
                passwordRef.current.value
            )
            .then((authUser) => {
                navigate("/overview")
            })
            .catch((error) => {
                alert(error.message);
            });
    } */

    const signIn = (e) => {
        e.preventDefault();

        auth
            .signInWithEmailAndPassword(
                emailRef.current.value,
                passwordRef.current.value
            )
            .then((authUser) => {
                //console.log(authUser);
            })
            .catch((error) => {
                alert(error.message);
            });

        navigate("/overview");
    };

    return (
        <main style={{ display: "flex" }} >

            <header className="signin__banner"
                style={{
                    backgroundSize: "cover",
                    backgroundImage: `linear-gradient(to top, #111 0%, transparent 90%), linear-gradient(to right, darkred, transparent), 
         url(
        "https://firebasestorage.googleapis.com/v0/b/abracadabra-website.appspot.com/o/resources%2Fweb_ref%2Fabracadabra-maincover03.webp?alt=media&token=cbfd2cc8-e9a9-4e59-9dcb-6dc8a85ec0de&_gl=1*1lw88vo*_ga*MjEzMDUzMDAxMy4xNjk2NDcyNDg2*_ga_CW55HF8NVT*MTY5NjQ3MjQ4Ni4xLjEuMTY5NjQ3Mjc3OC40LjAuMA.."
    )`,
                    backgroundPosition: "center center",
                }}
            >

                <div className="signin__signin">

                    <div className="signin__signin__msg">
                        <p>Acceso para personal autorizado</p>
                        <img
                            src={logo}
                            alt=""
                        />
                    </div>

                    <form >
                        <input ref={emailRef} type="email" placeholder="email" id="email" />

                        <input ref={passwordRef} type="Password" placeholder="password" id="password" />

                        <button
                            type="submit" onClick={signIn}>
                            Log In
                        </button>
                    </form>

                    {/* <form >
                        <input ref={emailRef} type="email" placeholder="email" id="email2" />

                        <input ref={passwordRef} type="Password" placeholder="password" id="password2" />

                        <button
                            type="submit" onClick={register}>
                            Create account
                        </button>
                    </form> */}

                </div>

            </header>

        </main>
    )
}

export default SignInScreen;