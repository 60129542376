import React from 'react';
import ReactAudioPlayer from 'react-audio-player';

const AudioPlayerAudio = ({ src, autoPlay = false, loop = false, volume = 1.0, onPlay }) => {
  return (
    <div>
      <ReactAudioPlayer
        src={src}
        controls
        autoPlay={autoPlay}
        loop={loop}
        volume={volume}
        onPlay={onPlay}
      />
    </div>
  );
};

export default AudioPlayerAudio;
