import React, {useState, useEffect} from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import firebaseApp from "./firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import ToasterProvider from "./providers/ToasterProvider";
import SignInScreen from './screens/SignInScreen';
import DashboardScreen from './screens/DashboardScreen';

function App() {
  const [usuarioGlobal, setUsuarioGlobal] = useState(null);

  useEffect(() => {
    const auth = getAuth(firebaseApp);
    const unsubscribe = onAuthStateChanged(auth, (usuarioFirebase) => {
      if (usuarioFirebase) {
        setUsuarioGlobal(usuarioFirebase);
      } else {
        setUsuarioGlobal(null);
      }
    });

    return () => {
      unsubscribe(); // Cleanup the listener when the component unmounts
    };
  }, []);

  return (
    <div className="app">
      <ToasterProvider />
      <Router onUpdate={() => window.scrollTo(0, 0)}>
        {!usuarioGlobal
          ? (
            <Routes>
              <Route exact path="/" element={<SignInScreen />} />
              <Route
                     exact
                     path="/overview"
                     element={<Navigate to="/" />}
                   />
            </Routes>
          ) : (
            <Routes>
              <Route
                     exact
                     path="/"
                     element={<Navigate to="/overview" />}
                   />
              <Route exact path="/overview" element={<DashboardScreen />} />
            </Routes>
          )
        }
      </Router>
    </div>
  );
}

export default App;