import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "firebase/compat/firestore";
import emailjs from '@emailjs/browser';
import { getAuth } from "firebase/auth";
import firebaseApp, { auth } from "../firebase.js";
import "./DashboardScreen.css";
import GoToTop from "../GoToTop";
/* import arrowdown from "../assets/arrow-down.svg"; */
import logo from "../assets/abracadabra-logo-white.svg";
import AudioPlayerAudio from "../components/PlayerAudio";
import ToggleSwitch from "../components/ToggleSwitch";
import { GiDuration } from "react-icons/gi";
import { FcLike } from "react-icons/fc";
import { FaRegFlag, FaPlay, FaUserCheck } from "react-icons/fa";
import { MdAccessTimeFilled } from "react-icons/md";
import { toast } from "react-hot-toast";

function formatReadableDate(isoTimestamp) {
    const date = new Date(isoTimestamp);

    // Format components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Format the date and time
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

function DashboardScreen() {
    const authuser = getAuth(firebaseApp);
    const [collectionData, setCollectionData] = useState([]);
    const [message, setMessage] = useState(null);
    const [loadingIcon, setLoadingIcon] = useState(false);
    const [showMessageBox, setShowMessageBox] = useState(false);
    const [data, setData] = useState(null);
    const navigate = useNavigate();

    const handleToggle = async (item) => {
        if (!item.audioVisible) {
            //setIsOn(true);
            await updateVisible(true, item.idAudio);
        } else {
            //setIsOn(false);
            await updateVisible(false, item.idAudio);
        };
        await fetchAllAudios();
    };

    const updateVisible = async (changevisible, idAudio) => {
        const response = await fetch('https://abracadabra-df76c6c76264.herokuapp.com/wapi/changeaudiovisible', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                idAudio: idAudio,
                visible: changevisible,
            }),
        });

        if (response.ok) {
            /* const data =  */await response.json();
        } else {
            const errorData = await response.json();
            console.error('Error updating Visible Status:', errorData.message);
        }
    }

    const fetchAllAudios = useCallback(async () => {
        setLoadingIcon(true);
        try {
            const [audioRes, likesRes, playsRes] = await Promise.all([
                fetch('https://abracadabra-df76c6c76264.herokuapp.com/wapi/data'),
                fetch('https://abracadabra-df76c6c76264.herokuapp.com/wapi/likesdata'),
                fetch('https://abracadabra-df76c6c76264.herokuapp.com/wapi/playsdata')
            ]);

            if (!audioRes.ok || !likesRes.ok || !playsRes.ok) {
                throw new Error('Failed to fetch data');
            }

            // Parse response data
            const [audioData, likesData, playsData] = await Promise.all([
                audioRes.json(),
                likesRes.json(),
                playsRes.json()
            ]);

            // Filter and process audio data
            const updatedData = audioData
                /* .filter((audio) => audio.audioVisible !== false) */
                .map((audio) => {
                    const likesCount = likesData.filter((likes) => likes.idAudio === audio.idAudio).length;
                    const isLiked = likesData.some((likes) => likes.idAudio === audio.idAudio);
                    const playsCount = playsData.filter((plays) => plays.idAudio === audio.idAudio).length;
                    return { ...audio, likesCount, isLiked, playsCount };
                });
            setCollectionData(updatedData.reverse())
            setLoadingIcon(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    })

    useEffect(() => {
        fetchAllAudios();
    }, []);

    const handleSubmit = (item, event) => {
        event.preventDefault();

        // Check if the email address is valid
        if (!item.userid) {
            console.error('Error: Recipient email address is empty');
            return;
        }

        // Define your template parameters including the message
        const templateParams = {
            email: item.userid,
            name: item.username,
            notes: `${item.username}, ¡Abracadabra te ha enviado un mensaje!`,
            message: message,
        };
        emailjs.send('service_w582xyd', 'template_5j52t78', templateParams, {
            publicKey: 'yUw-dDL3bKa5NJ5kz',
        }).then(
            function (response) {
                //console.log('SUCCESS!', response.status, response.text);
                toast.success(`El mensaje a ${item.username} ha sido enviado.`);
                setShowMessageBox(false);
            },
            function (err) {
                console.log('FAILED...', err);
            }
        );
    };

    return (
        <div>
            <div className='dashboardScreen'>
                <header className="dashboardScreen__banner"
                    style={{
                        backgroundSize: "cover",
                        backgroundImage: `linear-gradient(to top, #111 0%, transparent 90%), linear-gradient(to right, darkred, transparent), 
                     url(
                    "https://firebasestorage.googleapis.com/v0/b/abracadabra-website.appspot.com/o/resources%2Fweb_ref%2Fabracadabra-maincover03.webp?alt=media&token=cbfd2cc8-e9a9-4e59-9dcb-6dc8a85ec0de&_gl=1*1lw88vo*_ga*MjEzMDUzMDAxMy4xNjk2NDcyNDg2*_ga_CW55HF8NVT*MTY5NjQ3MjQ4Ni4xLjEuMTY5NjQ3Mjc3OC40LjAuMA.."
                )`,
                        backgroundPosition: "center center",
                    }}>
                    <div className='dashboardScreen__content'>
                        <div className="dashboardScreen__banner__content" >
                            <div className="dashboardScreen__banner__content__logo">
                                <img
                                    src={logo}
                                    alt=''
                                />
                            </div>
                            <h1>Dashboard de control de audios públicos de Abracadabra</h1>
                            {/* <img
                                className='dashboardScreen__back__img'
                                src={arrowdown}
                                alt=""
                            /> */}

                            <div style={{display:"flex", padding:"20px 0", alignItems:"center" }} >
                                <a
                                    style={{ cursor: "pointer", backgroundColor: "lightgray", color: "#111", padding: "20px", borderRadius: "20px", border: "none" }}
                                    onClick={() => {
                                        auth.signOut();
                                        navigate("/");
                                    }}>
                                    Log out</a>
                                <p style={{ marginLeft:"20px", fontSize:"14px" }} > {authuser?.currentUser?.email} </p>
                            </div>
                        </div>
                    </div>
                </header>

                {collectionData && collectionData.length > 0 &&
                    <>
                        {loadingIcon ? (
                            <div style={{
                                display: 'flex',
                                height: '100vh',
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                width: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                justifyContent: 'center',
                                alignItems: 'center',
                                zIndex: 50
                            }}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '50%',
                                    height: '50%'
                                }}>
                                    <img
                                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                        width={350}
                                        height={350}
                                        src="/images/loading2.gif"
                                        alt="Loading..."
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="dashboardScreen__map" >
                                {collectionData.map((item, index) => (
                                    <div className="dashboardScreen__map__item" key={index} >
                                        <div className="dashboardScreen__map__item__data" style={{ opacity: item.audioVisible ? 1 : 0.4 }} >
                                            <div style={{ display: "flex" }} >
                                                <div style={{ paddingBottom: "10px" }} >
                                                    <img
                                                        src={item.thumbnailUrl}
                                                        alt={item.audioTitle}
                                                        width={120}
                                                        height={120}
                                                        style={{ borderRadius: "20px" }}
                                                    />
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "column", paddingLeft: "20px" }} >
                                                    <div style={{ display: "flex", flexDirection: "column", gap: "8px" }} >
                                                        <div style={{ display: "flex", alignItems: "center" }} >
                                                            <p style={{ fontSize: "12px", backgroundColor: "gray", color: "#111", borderRadius: "50%", padding: "5px 8px 5px 8px" }} >id</p>
                                                            <span style={{ opacity: "0.6", paddingLeft: "10px" }} >{item.idAudio}</span>
                                                        </div>
                                                        <p><span style={{ fontSize: "18px", lineHeight: "24px" }} >{item.audioTitle}</span></p>
                                                        <p><span style={{ fontSize: "14px", opacity: "0.6" }} >{item.audioDescription}</span></p>

                                                        <div className="dashboardScreen__map__item__data__audiodetails">
                                                            <p><span>{item.audioCategory}</span></p>
                                                            <div>
                                                                <FaRegFlag size={12} style={{ opacity: "0.6" }} />
                                                                <p><span>{item.language}</span></p>
                                                            </div>
                                                            <div>
                                                                <GiDuration size={12} style={{ opacity: "0.6" }} />
                                                                <p><span>{Number(item.audioDuration).toFixed(0)}"</span></p>
                                                            </div>
                                                            <div>
                                                                <FcLike size={12} />
                                                                <p><span>{item.likesCount}</span></p>
                                                            </div>
                                                            <div>
                                                                <FaPlay size={12} style={{ opacity: "0.6" }} />
                                                                <p><span>{item.playsCount}</span></p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ display: "flex", gap: "20px", fontSize: "14px", opacity: "0.6", marginTop: "20px", alignItems: "center" }} >
                                                <p style={{ color: 'red', alignItems: "center" }} >{item?.username}</p>
                                                <p style={{ display: "flex", alignItems: "center" }} ><FaUserCheck size={12} style={{ opacity: "0.6" }} /><span style={{ paddingLeft: "5px" }}>{item?.userid}</span></p>
                                                {item.loadTimestamp &&
                                                    <p style={{ display: "flex", alignItems: "center" }} ><MdAccessTimeFilled size={12} style={{ opacity: "0.6" }} /><span style={{ paddingLeft: "5px" }}>{formatReadableDate(item.loadTimestamp)}</span></p>
                                                }
                                                {item.audioPaid ? (
                                                    <p><span style={{ backgroundColor: "yellow", padding: "10px", borderRadius: "20px", color: "#111" }} >Monetizable</span></p>
                                                ) : (
                                                    <p><span style={{ backgroundColor: "green", padding: "10px", borderRadius: "20px", color: "white" }}>Gratis</span></p>
                                                )}
                                                {/* <p>{item.audioVisible}</p> */}
                                            </div>
                                        </div>

                                        <div className="dashboardScreen__map__item__data__audioplayer">
                                            <AudioPlayerAudio
                                                src={item.audioUrl || ''}
                                                autoPlay={false}
                                                loop={false}
                                                volume={0.5}
                                            /* onPlay={handlePlay} */
                                            />
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "10px 0 10px 0" }} >
                                                <ToggleSwitch handleToggle={handleToggle} item={item} />
                                                <button
                                                    onClick={() => {
                                                        setData(item)
                                                        setShowMessageBox(true)
                                                    }}
                                                    style={{ backgroundColor: "lightgray", borderRadius: "20px", padding: "10px", marginLeft: "10px", color: "#111", border: "none", fontWeight: "extrabold", cursor: "pointer" }} >Mensaje</button>
                                            </div>
                                        </div>

                                    </div>

                                ))}
                            </div>
                        )}
                    </>
                }

                {showMessageBox &&
                    <div style={{
                        display: 'flex',
                        height: '100vh',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.95)',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 50
                    }}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '50%',
                            height: '50%'
                        }}>
                            <div style={{ display: "flex", flexDirection: "column" }} >
                                <div style={{ marginBottom: "20px" }} >
                                    <p style={{ marginBottom: "10px" }} >Escribe un mensaje para: {data.username}</p>
                                    <p>Con respecto al audio llamado: {data.audioTitle} </p>
                                </div>
                                <form style={{ display: "flex", flexDirection: "column" }} >
                                    <textarea style={{
                                        color: "#111",
                                        width: "100%",
                                        height: "200px",
                                        padding: "10px",
                                        fontSize: "16px",
                                        fontFamily: "Arial, sans-serif",
                                        lineHeight: "1.5",
                                        border: "1px solid #ccc",
                                        borderRadius: "8px",
                                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                        resize: "vertical",
                                        overflow: "auto",
                                    }} name="message" placeholder="Your Message" value={message || ""} onChange={(event) => setMessage(event.target.value)} ></textarea>

                                    <div style={{ display: "flex", flex: 1, width: "100%", marginTop: "20px", justifyContent: "center", gap: "20px" }} >
                                        <button style={{ width: "auto", color: "white", backgroundColor: "red", borderRadius: "20px", padding: "20px", border: "none", cursor: "pointer", fontSize: "14px" }}
                                            onClick={(event) => handleSubmit(data, event)} type="submit">Send Email</button>
                                        <button style={{ width: "auto", color: "white", backgroundColor: "#111", borderRadius: "20px", padding: "20px", border: "none", cursor: "pointer", fontSize: "14px" }}
                                            onClick={() => {
                                                setData(null);
                                                setMessage(null);
                                                setShowMessageBox(false)
                                            }} type="submit">Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                }

                <div className="dashboardScreen__form__copyright">
                    <p>2024 Abracadabra Inc. :: All rights reserved.</p>
                </div>

            </div>
            <GoToTop />
        </div>
    )
}

export default DashboardScreen;